import FirestoreModel from './FirestoreModel';

class User extends FirestoreModel {
    static collectionName = 'users';
    static allowedFields = [
      'communicationPreferences',
      'dashboardOrder',
      'email',
      'favoriteInsightQueries',
      'firstName',
      'isSchoolDogStaff',
      'lastName',
      'role',
      'roleSchoolIds',
      'schoolId',
      'permissions',
      'title',
      'autoSignOutData',
      'alwaysShowTakePicture',
    ]

    constructor(id, data) {
      super(id, data);
      if (!this.permissions) {
        this.permissions = {};
      }
      if (this.role) {
        this.friendlyRole = {
          'schooldog_site_admin': 'SchoolDog Site Admin',
          'district_safety_leader': 'District Safety Leader',
          'school_safety_leader': 'School Safety Leader—Primary',
          'school_safety_team': 'School Safety Leader',
          'school_resource_officer': 'School Resource Officer',
          'maintenance_work_order_manager': 'Maintenance Work Order Entry',
          'teacher': 'Educator/Non-Administrator',
          'custodian': 'Custodian/Tech Support',
          'administrative_assistant': 'Administrative Assistant/Clerical',
        }[this.role] || this.role;
      }
    }

    can(permission) {
      const permissionParts = permission.split(' at school:')
      const permissionBase = permissionParts[0];
      const schoolId = permissionParts[1];

      if (this.isSchoolDogStaff) {
        if (permissionBase === 'be proposed tasks') {
          return false;
        }
        return true;
      }

      if (permissionBase === 'view district') {
        return this.isDistrictUser() || this.can('manage district')
      }
      
      if (!this.permissions || !this.permissions[permissionBase]) {
        return false;
      }
  
      if (!this.permissions[permissionBase].hasPermission) {
        return false
      }

      if (this.permissions[permissionBase].always) {
        return true;
      }

      // If we make it this far, it either is a school-specific permission or they don't have the permission
      if (!schoolId) {
        return false;
      }

      const validSchoolsForUser = this.permissions[permissionBase]?.forSchools || [];
      if (!validSchoolsForUser.includes(schoolId)) {
        return false;
      }

      return true;
    }

    canAtSchool(permission, schoolId) {
      return this.can(`${permission} at school:${schoolId}`);
    }

    canAtAnySchool(permission) {
      if (this.isSchoolDogStaff || this.can(permission)) {
        return true;
      }

      const permissionParts = permission.split(' at school:')
      const permissionBase = permissionParts[0];
      if (!this.permissions || !this.permissions[permissionBase]) {
        return false;
      }
  
      if (!this.permissions[permissionBase].hasPermission) {
        return false
      }

      if (this.permissions[permissionBase].always || this.permissions[permissionBase].forSchools?.length) {
        return true;
      }

      return false;
    }

    getResolvedPermission({ can, canAtSchool, canAtAnySchool, schoolId }) {
      const checkMultiple = ((can ? 1 : 0) + (canAtSchool ? 1 : 0) + (canAtAnySchool ? 1 : 0)) > 1

      // potentially split can by | and check each one
      let meetsCanCondition = false
      let meetsCanAtSchoolCondition = false
      let meetsCanAtAnySchoolCondition = false
      if (can) {
        const canPossibilities = can.split('|');
        for (const canPossibility of canPossibilities) {
          if (this.can(canPossibility)) {
            meetsCanCondition = true;
            break;
          }
        }
        if (!meetsCanCondition && !checkMultiple) {
          return false
        }
      }
      
      if (canAtAnySchool) {
        const canPossibilities = canAtAnySchool.split('|');
        for (const canPossibility of canPossibilities) {
          if (this.canAtAnySchool(canPossibility)) {
            meetsCanAtAnySchoolCondition = true;
            break;
          }
        }
        if (!meetsCanAtAnySchoolCondition && !checkMultiple) {
          return false
        }
      }
      
      if (canAtSchool) {
        if (!schoolId) {
          return false;
        }
        const canPossibilities = canAtSchool.split('|');
        for (const canPossibility of canPossibilities) {
          if (this.canAtSchool(canPossibility, schoolId)) {
            meetsCanAtSchoolCondition = true;
            break;
          }
        }
        if (!meetsCanAtSchoolCondition && !checkMultiple) {
          return false
        }
      }

      return meetsCanCondition || meetsCanAtSchoolCondition || meetsCanAtAnySchoolCondition;
    }

    getLimitedSchoolIdsForPermission(permission) {
      // return null if access to all, otherwise an array of school ids
      if (this.isSchoolDogStaff) {
        return null
      }

      const permissionObj = (this.permissions || {})[permission] || {}
      if (permissionObj.always) {
        return null
      }

      return permissionObj.forSchools || []
    }

    isDistrictUser () {
      return [
        'SchoolDog Staff',
        'schooldog_site_admin',
        'district_safety_leader',
      ].includes(this.role)
    }
}

export default User;