<template>
<div class="d-flex" style="width: 100%; max-width: 100%;">
  <div class="flex-grow-1" style="width: 100%; max-width: 100%;">
    <v-toolbar
      color="#ffffff"
      style="border-bottom: 1px solid #1b7476;"
      width="100%">

      <div class="flex-grow-1" />

      <!-- Date Range Picker -->
      <v-menu
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="isShowingDateRangeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="dateRangeText"
            label="Date Range"
            class="mr-2"
            prepend-inner-icon="mdi-calendar"
            readonly
            hide-details="auto"
            outlined
            dense
            style="width: 240px; max-width: 240px;"
            v-on="on"
            clearable
            @click:clear="filters.dateRange = []"
          ></v-text-field>
        </template>
        <v-date-picker
          range
          v-model="filters.dateRange"
          @input="datesSelected"
        ></v-date-picker>
      </v-menu>


      <!-- Multi-select for Schools -->
      <v-select
        v-if="$vuetify.breakpoint.mdAndUp" 
        v-model="filters.schoolIds"
        hide-details="auto"
        :items="schoolItems"
        :menu-props="{ offsetY: true }"
        outlined
        dense
        label="Schools"
        style="max-width: 256px;"
        multiple>
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">
            {{ filters.schoolIds.length > 1 ? `${filters.schoolIds.length} Schools Selected` : item.text }}
          </span>
        </template>
      </v-select>

      <!-- Filter Button with Dropdown Menu -->
      <v-menu :close-on-content-click="false" offset-y max-width="480px" min-width="320px">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" color="grey darken-1" class="ml-1">
            Filters
            <v-icon>mdi-filter-variant</v-icon>
            <v-badge v-if="numberOfActiveMenuFilters" color="primary" :content="numberOfActiveMenuFilters" />
          </v-btn>
        </template>
        <v-list class="pb-2">
          <div class="pt-2 px-2 text-h6">
            Filters
          </div>
          <v-list-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-menu
              v-model="isShowingDateRangeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="dateRangeText"
                  label="Date Range"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details="auto"
                  outlined
                  dense
                  v-on="on"
                  clearable
                  @click:clear="filters.dateRange = []"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="filters.dateRange"
              ></v-date-picker>
            </v-menu>
          </v-list-item>
          <v-list-item>
            <users-select
              v-model="filters.userIds"
              hide-details="auto"
              outlined
              show-self
              dense
              label="Recorded by"
              multiple />
          </v-list-item>
          <v-list-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-select v-model="filters.schoolIds" hide-details="auto" :items="schoolItems" outlined dense label="Schools" multiple :menu-props="{ offsetY: true }">
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  {{ filters.schoolIds.length > 1 ? `${filters.schoolIds.length} Schools Selected` : item.text }}
                </span>
              </template>
            </v-select>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>
    <div class="d-flex flex-column flex-grow-1 px-4 pb-4 pt-2">
        <div class="text-h5 mb-1">
            Drills
        </div>
        <v-data-table
            :headers="headers"
            :items="items"
            :loading="isLoadingDrills"
            :items-per-page="50"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortByDesc"
            class="elevation-1">
            <template v-slot:[`item.recordedBy`]="{ item }">
                <user-link v-if="usersById[item.recordedByUserId]" :user-id="item.recordedByUserId" />
                <div v-else>Unknown</div>
            </template>
            <template v-slot:[`item.action`]="{ item }">
                <v-btn
                    color="grey darken-1"
                    icon
                    @click="$router.push(`/drills/drill/${item.id}`)"
                >
                    <v-icon>mdi-chevron-right</v-icon>
                </v-btn>
            </template>
        </v-data-table>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CheckPermissionMixin from '../../mixins/CheckPermissionMixin';
import UsersSelect from '../../components/common/UsersSelect.vue';
import Drill from '../../models/Drill';
import UserLink from '../../components/common/UserLink.vue';

export default {
  name: 'ViewDrillsPage',
  mixins: [
    CheckPermissionMixin,
  ],
  canAtAnySchool: 'record drills',
  components: {
    UsersSelect,
    UserLink,
  },
  data () {
    return {
      isShowingDateRangeMenu: false,
      filters: {
        dateRange: [],
        userIds: [],
        schoolIds: [],
        drillTypes: [],
        eventTypes: [],
      },
      drills: [],
      isLoadingDrills: false,
      sortBy: 'date',
      sortByDesc: true,
    }
  },
  computed: {
    ...mapGetters('app', [
      'getSchoolById',
      'schools',
    ]),
    ...mapState('app', [
      'usersById',
      'user',
    ]),
    headers () {
      return [
        { text: 'School', value: 'schoolName' },
        { text: 'Date', value: 'date' },
        { text: 'Drill Type', value: 'drillType' },
        { text: 'Recorded By', value: 'recordedBy' },
        { text: '', value: 'action', sortable: false },
      ];
    },
    items () {
      return this.filteredDrills.map(drill => ({
        ...drill,
        schoolName: this.getSchoolById(drill.schoolId).name,
        recordedBy: this.usersById[drill.recordedByUserId] ? `${this.usersById[drill.recordedByUserId].firstName} ${this.usersById[drill.recordedByUserId].lastName}` : 'Unknown',
        date: this.$moment(drill.drillDate).format('MM/DD/YYYY'),
        drillType: drill.getFriendlyDrillType(),
      }));
    },
    numberOfActiveMenuFilters () {
      let number = 0;
      if (this.$vuetify.breakpoint.mdAndUp) {

        if (this.filters.userIds.length > 0) {
          number++;
        }
      } else {
        if (this.filters.dateRange.length === 2) {
          number++;
        }
        if (this.filters.userIds.length > 0) {
          number++;
        }
        if (this.filters.schoolIds.length > 0) {
          number++;
        }
      }
      return number;
    },
    schoolItems () {
      const limitedSchoolIds = this.user.getLimitedSchoolIdsForPermission('record drills');
      return this.schools
        .filter(school => !limitedSchoolIds || limitedSchoolIds.includes(school.id))
        .map(school => ({
          text: school.name,
          value: school.id,
        })).sort((a, b) => a.text.localeCompare(b.text));
    },
    dateRangeText() {
      if (this.filters.dateRange.length === 2) {
        // Format the dates as needed
        const startDate = new Date(`${this.filters.dateRange[0]} 00:00:00`).toLocaleDateString();
        const endDate = new Date(`${this.filters.dateRange[1]} 00:00:00`).toLocaleDateString();
        return `${startDate} - ${endDate}`;
      } else {
        return 'All Time';
      }
    },
    filteredDrills () {
      return this.drills.filter(drill => {
        if (this.filters.dateRange.length === 2) {
          const drillDate = drill.drillDate;
          const startDate = this.filters.dateRange[0];
          const endDate = this.filters.dateRange[1];
          if (drillDate < startDate || drillDate > endDate) {
            return false;
          }
        }
        if (this.filters.userIds.length > 0 && !this.filters.userIds.includes(drill.recordedByUserId)) {
          return false;
        }
        if (this.filters.schoolIds.length > 0 && !this.filters.schoolIds.includes(drill.schoolId)) {
          return false;
        }
        return true;
      });
    },
  },
  methods: {
    async loadDrills () {
      this.isLoadingDrills = true;
      try {
        const limitedSchoolIds = this.user.getLimitedSchoolIdsForPermission('record drills');
        if (!limitedSchoolIds) {
          this.drills = await Drill.getAll();
        } else {
          this.drills = await Drill.getAllWhere([
            [
              'schoolId',
              'in',
              limitedSchoolIds,
            ],
          ]);
        }
      } finally {
        this.isLoadingDrills = false;
      }
    },
    datesSelected() {
      if (!this.filters.dateRange || this.filters.dateRange.length != 2) {
        return;
      }
      // if both dates are selected, sort them
      const startDate = new Date(this.filters.dateRange[0].replace(/-/g, '/'));
      const endDate = new Date(this.filters.dateRange[1].replace(/-/g, '/'));
      if (startDate > endDate) {
        const newStart = this.filters.dateRange[1];
        this.filters.dateRange[1] = this.filters.dateRange[0];
        this.filters.dateRange[0] = newStart;
      }
    },
    handleQueryParams() {
      const query = this.$route.query;
      if (query.dateRange) {
        this.filters.dateRange = query.dateRange.split(',');
      }
      if (query.schoolIds) {
        this.filters.schoolIds = query.schoolIds.split(',');
      }
      if (query.walkTypes) {
        this.filters.walkTypes = query.walkTypes.split(',');
      }
      if (query.userIds) {
        this.filters.userIds = query.userIds.split(',');
      }
      if (query.awaitingMyAction) {
        this.filters.awaitingMyAction = query.awaitingMyAction === 'true';
      }
      if (query.showArchived) {
        this.filters.showArchived = query.showArchived === 'true';
      }
      if (query.currentTab) {
        this.currentTab = parseInt(query.currentTab);
      }
    },
  },
  mounted () {
    this.handleQueryParams();
    this.loadDrills();
  },
  watch: {
    filters: {
      handler () {
        this.$router.replace({
          query: {
            ...this.filters,
            dateRange: this.filters.dateRange.join(','),
            schoolIds: this.filters.schoolIds.join(','),
            userIds: this.filters.userIds.join(','),
          },
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-select__selections {
  height: 40px;
  overflow: ell;
}
</style>
