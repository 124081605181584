<template>
<v-dialog :value="value" @input="$emit('input', $event)" max-width="528px">
    <v-card>
        <v-card-title>
          Start new SchoolDog Walk
          <v-spacer />
          <v-btn @click="$emit('input', false)" icon color="grey darken-1">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-subtitle> Enter a few details to get started. </v-card-subtitle>
        <v-card-text>
        <v-alert
          v-if="currentlyInProgressWalk"
          dense
          type="info"
        >
          You already have an in-progress SchoolDog Walk at this school. <a :href="`/walks/walk/${currentlyInProgressWalk.id}/inprogress`" style="color: white">Open that SchoolDog Walk instead.</a>
        </v-alert>
        <v-alert
          v-else-if="hasRecentlyCompletedWalk"
          dense
          type="info"
        >
          You recently completed a SchoolDog Walk at this school. As a reminder, you can log multiple entries before completing a SchoolDog Walk to save time and keep relevant data grouped together.
        </v-alert>
        <school-select
            v-if="value"
            v-model="walkData.schoolId"
            permission="log entries" />
        <v-checkbox v-model="walkData.hasCollaborators" hide-details="auto" label="I will be collaborating with other SchoolDog users during this SchoolDog Walk." />
        <div v-if="walkData.hasCollaborators">
          <users-select
              v-model="walkData.collaborators"
              multiple
              :valid-user-ids="getEligibleCollaboratorsForWalk(walkData).map(user => user.value)"
              dense
              label="Collaborators"
              class="mt-2" />
        </div>
        </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="$emit('input', false)" color="grey darken-1" text> Cancel </v-btn>
        <v-btn
            @click="createWalk()"
            :disabled="!isWalkValid || isCreatingWalk"
            color="primary"
        >
            Start SchoolDog Walk
        </v-btn>
        </v-card-actions>
    </v-card>
</v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import SchoolSelect from '../common/SchoolSelect.vue'
import UsersSelect from '../common/UsersSelect.vue'
export default {
  components: {
    SchoolSelect,
    UsersSelect,
  },
  name: 'CreateWalkDialog',
  props: {
    value: Boolean,
  },
  data () {
    return {
      isCreatingWalk: false,
      walkData: {
        schoolId: null,
        hasCollaborators: false,
        collaborators: [],
      },
    }
  },
  computed: {
    ...mapGetters('app', [
      'getEligibleCollaboratorsForWalk',
      'getRubricById',
    ]),
    ...mapState('app', [
      'isOffline',
      'organizationSettings',
      'user',
      'walks',
      'rubrics',
    ]),
    isWalkValid () {
      return Boolean(this.walkData.schoolId)
    },
    currentlyInProgressWalk () {
      if (!this.walkData.schoolId) {
        return null
      }
      return this.walks.find(walk => !walk.timeArchived && walk.schoolId === this.walkData.schoolId && walk.status === 'inprogress' && walk.leaderUserId === this.user.id)
    },
    hasRecentlyCompletedWalk () {
      if (!this.walkData.schoolId) {
        return null
      }
      return this.walks.some(walk => !walk.timeArchived && walk.schoolId === this.walkData.schoolId && walk.status === 'complete' && walk.leaderUserId === this.user.id && new Date(walk.timeCompleted) > new Date(Date.now() - 60 * 60 * 1000))
    },
  },
  methods: {
    ...mapMutations('app', [
      'setWalks',
    ]),
    ...mapActions('app', [
      'createModel',
    ]),
    resetWalkData () {
      this.walkData = {
        schoolId: null,
        hasCollaborators: false,
        collaborators: [],
      }
    },
    async createWalk() {
      this.isCreatingWalk = true;
      try {
        let walk = await this.createModel({
          model: 'Walk',
        })
        walk.collaborators = this.walkData.hasCollaborators ? (this.walkData.collaborators || []) : []
        walk.timeframe = this.walkData.timeframe
        walk.schoolId = this.walkData.schoolId
        walk.observations = []
        walk.observationsById = {}
        walk.assessedCategories = []
        walk.createdByUserId = this.user.id
        walk.leaderUserId = this.user.id
        if (this.user.isDistrictUser()) {
          walk.walkType = 'district'
        } else if (this.user.roleSchoolIds.includes(walk.schoolId)) {
          walk.walkType = 'school'
        } else {
          walk.walkType = 'partner'
        }
        walk.intendedCategories = []
        walk.status = 'inprogress';
        walk.stage = 'context'
        walk.shouldSendNeighborhoodWatch = false
        walk.hasSentNeighborhoodWatch = false
        walk.rubrics = [];
        this.rubrics.forEach(rubric => {
          if (!rubric.enabled || (rubric.districtWalksOnly && !walk.walkType === 'district')) {
            return;
          }
          const rubricCopy = { ...rubric };
          if (rubricCopy.skipByDefault) {
            rubricCopy.skip = true;
          }
          walk.rubrics.push(rubricCopy)
        });
        walk = await walk.save()
        if (!this.isOffline) {
          walk.collaborators.forEach(userId => {
            this.runFunction('sendCollaboratorEmail', {
              newCollaboratorUserId: userId,
              walkId: walk.id,
            });
          });
        }
        this.setWalks([
          ...this.walks,
          walk,
        ])
        this.$router.push(`/walks/walk/${walk.id}/inprogress`);
        this.$emit('input', false);
      } catch (e) {
        console.warn('Error creating new walk', e);
        /// TODO
      } finally {
        this.isCreatingWalk = false;
      }
    },
  },
  watch: {
    value () {
      if (this.value) {
        this.resetWalkData()
      }
    },
  },
}
</script>
