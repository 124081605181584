<template>
  <v-card
    v-if="isLoading || (topWalkersLeaderboard && topUsers.length)"
    class="d-flex flex-column flex-grow-1 pa-1">
    <v-card-title><glossary-term text="Top Walkers" /></v-card-title>
    <v-card-text>
      <div
        v-if="isLoading"
        style="width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;">
        <v-progress-circular
          indeterminate
          color="primary" />
      </div>
      <v-list v-else>
        <v-list-item v-for="user in topUsers" :key="user.id">
          <v-list-item-content>
            <v-list-item-title class="d-flex align-center">
              <v-avatar
                class="mr-1"
                size="24"
                left
                :style="`border: 2px solid ${user.rankColor} !important;`"
              >
                {{ user.rank }}
              </v-avatar>
              <div>
                <user-link :user-id="user.id" />
                <div class="font-weight-thin">{{ user.numObservations }} entr{{ user.numObservations === 1 ? 'y' : 'ies' }} logged</div>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import GlossaryTerm from '../common/GlossaryTerm.vue'
import TopWalkersLeaderboard from '../../models/TopWalkersLeaderboard'
import UserLink from '../common/UserLink.vue'

export default {
  components: {
    GlossaryTerm,
    UserLink,
  },
  data () {
    return {
      isLoading: false,
      topWalkersLeaderboard: null,
    }
  },
  computed: {
    ...mapState('app', [
      'usersById',
    ]),
    topUsers() {
      let topUsers = [];
      if (!this.topWalkersLeaderboard) {
        return [];
      }

      Object.keys(this.topWalkersLeaderboard.data).forEach((userId) => {
        const user = this.usersById[userId];
        if (!user) {
          return;
        }
        topUsers.push({
          ...user,
          numObservations: this.topWalkersLeaderboard.data[userId],
        });
      });

      topUsers = topUsers.filter(user => user.numObservations > 0);
      topUsers.sort((a, b) => b.numObservations - a.numObservations);
      topUsers.forEach((user, index) => {
        user.rank = index + 1;
        user.rankColor = [
          '#FFD700',
          '#C0C0C0',
          '#cd7f32',
        ][index]
      });
      return topUsers.slice(0, 5);
    },
  },
  methods: {
    async loadData () {
      this.isLoading = true;
      this.topWalkersLeaderboard = await TopWalkersLeaderboard.get();
      this.isLoading = false;
    },
  },
  mounted () {
    this.loadData();
  },
}
</script>

<style>
</style>
