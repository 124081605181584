import { db } from '@/firebase-setup'
import { doc, collection, addDoc } from 'firebase/firestore';

import FirestoreModel from './FirestoreModel';

class Task extends FirestoreModel {
    static collectionName = 'tasks';
    static allowedFields = [
      'walkId',
      'schoolId',
      'imagePaths',
      'location',
      'title',
      'description',
      'timestamp',
      'priority', // numerical [1, 2, 3]
      'observationId', // optional
      'observation',
      'assignedToUserId',
      'createdByUserId', // if not set, it was generated by SchoolDog
      'declinedByUserId',
      'dueDate',
      'comments',
      'timeCompleted',
      'shouldEmailCreatorWhenComplete',
      'status',
      'delegatedByUserId',
      'completedByUserId',
      'notes',
    ]

    constructor(id, data) {
      super(id, data);
    }

    async delegateTask(delegatedByUserId, assignedToUserId) {
      // Update the task's assignedToUserId field
      this.assignedToUserId = assignedToUserId;
      this.delegatedByUserId = delegatedByUserId;
      this.status = 'Assigned';
      await this.save();

      // Write a history entry
      const historyData = {
        type: 'task_delegated',
        delegatedByUserId,
        assignedToUserId,
        timestamp: new Date(),
      };
      await this.writeHistory(historyData);

      return this;
    }

    async completeTask(completedByUserId) {
      this.status = 'Complete';
      this.timeCompleted = new Date();
      this.completedByUserId = completedByUserId;
      await this.save();

      // Write a history entry
      const historyData = {
        type: 'task_completed',
        completedByUserId,
        timestamp: new Date(),
      };
      await this.writeHistory(historyData);

      return this;
    }

    async declineTask(declinedByUserId) {
      this.status = 'Declined';
      this.declinedByUserId = declinedByUserId;
      await this.save();

      // Write a history entry
      const historyData = {
        type: 'task_declined',
        declinedByUserId,
        timestamp: new Date(),
      };
      await this.writeHistory(historyData);

      return this;
    }

    async openTask(openedByUserId) {
      this.status = 'Open';
      await this.save();

      // Write a history entry
      const historyData = {
        type: 'task_opened',
        openedByUserId,
        timestamp: new Date(),
      };
      await this.writeHistory(historyData);

      return this;
    }

    async setTaskAssigned(setAssignedByUserId) {
      this.status = 'Assigned';
      await this.save();

      // Write a history entry
      const historyData = {
        type: 'task_assigned',
        setAssignedByUserId,
        timestamp: new Date(),
      };
      await this.writeHistory(historyData);

      return this;
    }

    async writeHistory(historyData) {
      if (!this.id) {
        throw new Error('Task must be saved to Firestore before writing history');
      }

      const taskRef = doc(db, 'tasks', this.id);
      const historyRef = collection(taskRef, 'history');
      const historyDoc = await addDoc(historyRef, historyData);
      return historyDoc;
    }
}

export default Task;
