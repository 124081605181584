<template>
  <v-card
    class="d-flex flex-column flex-grow-1 pa-1">
    <v-card-title>Action Panel</v-card-title>
    <v-card-text>
     <v-btn
      v-if="$canAtAnySchool('log entries')"
      @click="isLogEntryDialogVisible = true"
      style="width: 100%"
      class="mb-1"
      outlined
      color="primary">
      <v-icon class="mr-1">mdi-pencil-box-multiple</v-icon>Log Entry
    </v-btn>
    <v-btn
      v-if="$canAtAnySchool('propose tasks')"
      @click="openCompose"
      style="width: 100%"
      class="mb-1"
      outlined
      color="primary">
      <v-icon class="mr-1">mdi-clipboard-text</v-icon>Propose Task
    </v-btn>
    <v-btn
      v-if="$canAtAnySchool('log entries')"
      @click="isCreateWalkDialogVisible = true"
      style="width: 100%"
      class="mb-1"
        outlined
        color="primary">
        <v-icon class="mr-1">mdi-walk</v-icon>Start SchoolDog Walk
      </v-btn>
      <v-btn
        v-if="$canAtAnySchool('record drills')"
        @click="$router.push('/drills/record')"
        style="width: 100%"
        class="mb-1"
        outlined
        color="primary">
        <v-icon class="mr-1">mdi-whistle</v-icon>Record Drill
      </v-btn>
    </v-card-text>

    <log-entry-dialog
      v-model="isLogEntryDialogVisible"
      floating />

    <create-walk-dialog v-model="isCreateWalkDialogVisible" />

    <TaskCompose ref="compose" />
  </v-card>
</template>

<script>
import LogEntryDialog from '../walks/LogEntryDialog.vue'
import CreateWalkDialog from '../walks/CreateWalkDialog.vue'

export default {
  components: {
    LogEntryDialog,
    CreateWalkDialog,
    TaskCompose: () => import('../tasks/TaskCompose.vue'),
  },
  data () {
    return {
      isLogEntryDialogVisible: false,
      isCreateWalkDialogVisible: false,
    }
  },
  methods: {
    openCompose() {
      this.$refs.compose.open()
    },
  },
}
</script>

<style>
</style>
