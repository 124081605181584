<template>
     <v-card class="px-1 pt-1">
      <v-card-title class="flex-grow-1 flex-row d-flex">
        {{ initialInsightData ? (initialInsightData.insightName || 'My Logged Entries') : 'Explore entries over time' }}
      </v-card-title>
      <v-card-subtitle v-if="dashboard">
        <router-link to="/insights">View more on the Data Insights page</router-link>
        </v-card-subtitle>
      <v-card-text>
        <div v-if="!dashboard" class="mb-1">
          <div v-if="user.favoriteInsightQueries && user.favoriteInsightQueries.length > 0" class="mb-1">
            <div v-if="$vuetify.breakpoint.mdAndUp" class="d-flex flex-wrap">
              <div
                v-for="(favoriteInsight, index) in user.favoriteInsightQueries"
                :key="index"
              >
                <v-btn
                  @click="setInsightFromFavorite(favoriteInsight)"
                  outlined
                  color="grey darken-1"
                  class="mr-1 mb-1"
                >
                  <v-icon>
                    mdi-star
                  </v-icon>
                  {{ favoriteInsight.insightName }}
                </v-btn>
              </div>
            </div>
            <div v-else>
              <!-- Button that opens a menu and then calls setInsightFromFavorite -->
              <v-menu offset-y>
                <template v-slot:activator="{ on }">
                  <v-btn
                    outlined
                    color="grey darken-1"
                    v-on="on"
                  >
                    <v-icon>
                      mdi-star
                    </v-icon>
                    Favorites
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item
                    v-for="(favoriteInsight, index) in user.favoriteInsightQueries"
                    :key="index"
                    @click="setInsightFromFavorite(favoriteInsight)"
                  >
                    <v-list-item-title>
                      {{ favoriteInsight.insightName }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
          <div class="mb-1">
            How would you like to explore your data?
          </div>
          <v-select
            v-model="viewMode"
            :menu-props="{ offsetY: true }"
            :items="viewModeOptions"
            dense
            hide-details="auto"
            outlined
            label="Explore data by"
          ></v-select>
        </div>
        <observation-category-select
          v-if="['category', 'item'].includes(viewMode) && !dashboard"
          v-model="selectedObservationCategoryId"
          @input="selectedObservationItemId = null; $forceUpdate();"
          class="mb-1"
        ></observation-category-select>
        <observation-item-select
          v-if="'item' === viewMode && !dashboard"
          v-model="selectedObservationItemId"
          name="observation-item"
          :observation-category-id.sync="selectedObservationCategoryId"
          class="mb-1"
        ></observation-item-select>
        <school-select
          v-if="'location' === viewMode && !dashboard"
          v-model="selectedLocationSchoolId"
          :permission="'view data insights'"
          class="mb-1"
          clearable
        ></school-select>
        <observation-location-select
          v-if="'location' === viewMode && selectedLocationSchoolId && !dashboard"
          :value="selectedLocation"
          @input="selectedLocation = $event"
          :school-id="selectedLocationSchoolId"
          name="observation-location"
          class="my-1"
          clearable
        ></observation-location-select>
        <tag-select
          v-if="'tag' === viewMode && !dashboard"
          v-model="selectedTag"
          class="my-1"
          hide-details
          label="Tag"
          name="tag"
          outlined
          dense />
        <users-select
          v-if="'user' === viewMode && !dashboard"
          v-model="selectedUserId"
          label="User"
          class="mb-1"
          dense
          show-self
          clearable />
        <div v-if="canShowChart" class="mb-1">
          <v-divider v-if="!dashboard" class="mb-1"></v-divider>
          <v-select
            v-if="!(dashboard && insightName)"
            v-model="selectedObservationType"
            :items="observationTypeOptions"
            :menu-props="{ offsetY: true }"
            class="mb-1"
            dense
            hide-details="auto"
            outlined
            label="Filter by entry type"
          ></v-select>
          <v-divider v-if="!(dashboard && insightName)" class="mb-1"></v-divider>
          <div v-if="!dashboard && insightCount && !insightCountError">
            View observation data by clicking on points on the chart below or <u><a @click.prevent="initialObservationExplorerData = {}; showObservationExplorerDialog = true">view/download all searched observations</a></u>.
          </div>
          <div>
            <counts-by-school-chart
              @show-modal="initialObservationExplorerData = $event; showObservationExplorerDialog = true;"
              :dashboard="dashboard"
              :allow-district-line="['item', 'category', 'user', 'all'].includes(viewMode) && user.can('view data insights')"
              :title="chartTitle"
              :observation-type="selectedObservationType"
              :specific-school-id="'location' === viewMode ? selectedLocationSchoolId : null"
              :data="insightCount.countsByMonth"></counts-by-school-chart>
          </div>
          <div v-if="!dashboard">
            <v-btn
              v-if="!isFavorite"
              @click="newInsightName = chartTitle; isAddFavoriteDialogVisible = true"
              outlined
              color="primary"
            >
              <v-icon>mdi-star-outline</v-icon>
              Add to Favorites
            </v-btn>
            <v-btn
              v-else
              @click="removeFavoriteInsight()"
              outlined
              color="primary">
              <v-icon>mdi-star</v-icon>
              Remove from Favorites
            </v-btn>
          </div>
        </div>
        <div v-else-if="isLoading" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
        </div>
        <div v-else-if="insightCountError" class="mb-1">
          <div class="d-flex justify-center text-center" :class="{ 'my-6': !dashboard }">
            <div style="max-width: 100%;">
              <div style="width: 500px; max-width: 100%" class="text-subtitle-1 mb-2">There is no data available{{ !dashboard ? ' for your search' : ''}}. <span v-if="isOffline">Please try again when online.</span></div>
              <img :style="`width: ${dashboard ? '280px' : '360px'}; max-width: 90%`" src="@/assets/images/empty_folder.png" />
            </div>
          </div>
        </div>
      </v-card-text>
      <observation-explorer-dialog
        v-if="showObservationExplorerDialog"
        v-model="showObservationExplorerDialog"
        :school-id="initialObservationExplorerData.schoolId"
        :observation-category-id="selectedObservationCategoryId"
        :observation-item-id="selectedObservationItemId"
        :observation-location="selectedLocation"
        :observation-type="selectedObservationType"
        :user-id="selectedUserId"
        :walk-type="initialObservationExplorerData.walkType"
        :start-date="initialObservationExplorerData.startDate"
        :tag="selectedTag"
        :report-title="chartTitle"
        :end-date="initialObservationExplorerData.endDate" />
      <v-dialog v-model="isAddFavoriteDialogVisible" max-width="600px">
        <v-card>
          <v-card-title>Add to Favorites?</v-card-title>
          <v-card-text>
            <div>
              <div class="mb-1">
                <div>
                  You can give this data a name to help you find it later.
                </div>
                <v-text-field
                  v-model="newInsightName"
                  class="my-2"
                  label="Custom name"
                  outlined
                  dense
                  hide-details="auto"
                />
              </div>
              <div class="d-flex justify-end">
                <v-btn
                  @click="isAddFavoriteDialogVisible = false"
                  text
                  color="grey darken-1"
                >
                  Cancel
                </v-btn>
                <v-btn
                  @click="addFavoriteInsight()"
                  :disabled="!newInsightName"
                  color="primary"
                >
                  Add to Favorites
                </v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card>
</template>

<script>
import SchoolSelect from '../../components/common/SchoolSelect.vue';
import ObservationCategorySelect from '../../components/walks/ObservationCategorySelect.vue';
import ObservationItemSelect from '../../components/walks/ObservationItemSelect.vue';
import ObservationLocationSelect from '../../components/walks/ObservationLocationSelect.vue';
import CountsBySchoolChart from '../../components/insights/CountsBySchoolChart.vue';
import ObservationExplorerDialog from '../../components/insights/ObservationExplorerDialog.vue';
import InsightCount from './../../models/InsightCount';
import UsersSelect from '../../components/common/UsersSelect.vue';
import TagSelect from './TagSelect.vue';

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  components: {
    CountsBySchoolChart,
    ObservationCategorySelect,
    ObservationItemSelect,
    ObservationLocationSelect,
    ObservationExplorerDialog,
    SchoolSelect,
    TagSelect,
    UsersSelect,
  },
  props: {
    dashboard: Boolean,
    insightName: String,
  },
  data () {
    return {
      insightCount: null,
      insightCountError: false,
      selectedTag: null,
      selectedObservationItemId: null,
      selectedObservationCategoryId: null,
      selectedLocation: null,
      selectedLocationSchoolId: null,
      selectedUserId: null,
      selectedObservationType: 'concern',
      viewMode: null, // category, item, or tag
      initialObservationExplorerData: {},
      showObservationExplorerDialog: false,
      isLoading: false,
      isAddFavoriteDialogVisible: false,
      newInsightName: '',
    }
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'schoolLocationsById',
      'observationSettings',
      'user',
      'usersById',
    ]),
    ...mapGetters('app', [
      'getSchoolById',
    ]),
    observationCategories() {
      if (this.observationSettings) {
        return this.observationSettings.categories;
      }
      return [];
    },
    observationItemsById () {
      const items = {};
      this.observationSettings.items.forEach((item) => {
        items[item.id] = item;
      });
      return items;
    },
    viewModeOptions () {
      const options = [
        {
          value: 'all',
          text: 'All data',
        },
        {
          value: 'category',
          text: 'Category',
        },
        {
          value: 'item',
          text: 'Observation',
        },
        {
          value: 'location',
          text: 'Location',
        },
        {
          value: 'user',
          text: 'User',
        },
        {
          value: 'tag',
          text: 'Tag',
        },
        // {
        //   value: 'tag',
        //   text: 'Tag',
        // },
      ];
      return options;
    },
    observationTypeOptions () {
      return [
        {
          value: 'concern',
          text: 'Concerns',
        },
        {
          value: 'praise',
          text: 'Praises',
        },
        {
          value: 'other',
          text: 'Other Entries',
        },
      ];
    },
    chartTitle () {
      if (this.viewMode === 'all') {
        return `All ${this.observationTypeOptions.find((option) => option.value === this.selectedObservationType).text}`;
      }
      if (this.viewMode === 'category') {
        const category = this.observationCategories.find((category) => category.id === this.selectedObservationCategoryId);
        if (!category) {
          return ''
        }
        // remove spaces around any backslashes in the category
        const filteredCategory = category.label.replace(/\s*\/\s*/g, '/');
        const observationsLabel = this.observationTypeOptions.find((option) => option.value === this.selectedObservationType).text;
        return `${filteredCategory} ${observationsLabel}`;
      }
      if (this.viewMode === 'location') {
        const schoolName = this.getSchoolById(this.selectedLocationSchoolId).name;
        const observationsLabel = this.observationTypeOptions.find((option) => option.value === this.selectedObservationType).text;
        return `${schoolName} ${this.selectedLocation} ${observationsLabel}`;
      }
      if (this.viewMode === 'user') {
        const observationsLabel = this.observationTypeOptions.find((option) => option.value === this.selectedObservationType).text;
        return `${this.selectedUser.firstName} ${this.selectedUser.lastName} ${observationsLabel}`;
      }
      if (this.viewMode === 'item') {
        const item = this.observationItemsById[this.selectedObservationItemId]
        if (!item) {
          return ''
        }
        const observationsLabel = this.observationTypeOptions.find((option) => option.value === this.selectedObservationType).text;
        return `${item.label} ${observationsLabel}`;
      }
      if (this.viewMode === 'tag') {
        const observationsLabel = this.observationTypeOptions.find((option) => option.value === this.selectedObservationType).text;
        return `${this.selectedTag} ${observationsLabel}`;
      }
      return ''
    },
    canShowChart () {
      return this.viewMode === 'category' && this.selectedObservationCategoryId && this.insightCount
        || this.viewMode === 'item' && this.selectedObservationItemId && this.insightCount
        || this.viewMode === 'location' && this.selectedLocation && this.insightCount
        || this.viewMode === 'user' && this.selectedUserId && this.insightCount
        || this.viewMode === 'tag' && this.selectedTag && this.insightCount
        || this.viewMode === 'all' && this.insightCount;
    },
    selectedUser () {
      return this.usersById[this.selectedUserId];
    },
    insightObject () {
      return {
        viewMode: this.viewMode,
        selectedObservationCategoryId: this.selectedObservationCategoryId,
        selectedObservationItemId: this.selectedObservationItemId,
        selectedLocation: this.selectedLocation,
        selectedLocationSchoolId: this.selectedLocationSchoolId,
        selectedUserId: this.selectedUserId,
        selectedObservationType: this.selectedObservationType,
      }
    },
    isFavorite () {
      if (!this.user?.favoriteInsightQueries) {
        return false;
      }
      return this.user.favoriteInsightQueries.some((favoriteInsight) => {
        for (const key in favoriteInsight) {
          if (key === 'insightName') {
            continue;
          }
          if (favoriteInsight[key] !== this.insightObject[key]) {
            return false;
          }
        }
        return true;
      })
    },
    initialInsightData () {
      if (this.dashboard) {
        if (this.insightName) {
          const favoriteInsight = this.user?.favoriteInsightQueries.find((favoriteInsight) => favoriteInsight.insightName === this.insightName);
          if (favoriteInsight) {
            return favoriteInsight;
          }
        } else {
          return {
            viewMode: 'user',
            selectedUserId: this.user.id,
          }
        }
      }
      return null;
    },
  },
  methods: {
    ...mapActions('app', [
      'showSuccess',
    ]),
    async getInsightCount(insightCountId) {
      this.isLoading = true;

      try {
        // if user has access to all data insights, just pull the raw docs. Otherwise, need to abstract through the function
        let insightCount = null;
        if (this.user.permissions['view data insights']?.always || this.user.isSchoolDogStaff) {
          insightCount = await InsightCount.getById(insightCountId);
        } else {
          const insightCountData = await this.runFunction('getInsightCount', { insightCountId });
          insightCount = new InsightCount(insightCountId, insightCountData)
        }
        if (!insightCount || !insightCount.countsByMonth || Object.keys(insightCount.countsByMonth).length === 0) {
          throw new Error('No data found');
        }
        this.isLoading = false;
        
        return insightCount;
      } catch (e) {
        this.isLoading = false;
        throw e;
      }
    },
    addFavoriteInsight () {
      this.user.favoriteInsightQueries = this.user.favoriteInsightQueries || [];
      this.user.favoriteInsightQueries.push({
        ...this.insightObject,
        insightName: this.newInsightName,
      });
      this.user.dashboardOrder = this.user.dashboardOrder || [];
      this.user.dashboardOrder.push({
        value: `insight-query-card-${this.newInsightName}`,
        label: `Data Insights: ${this.newInsightName}`,
      });
      this.runFunction('editUser', {
        userId: this.user.id,
        updatedUserData: this.user.toJson(),
      });
      this.isAddFavoriteDialogVisible = false;
      this.showSuccess('Insight added to favorites');
    },
    removeFavoriteInsight () {
      this.user.favoriteInsightQueries = this.user.favoriteInsightQueries.filter((favoriteInsight) => {
        for (const key in favoriteInsight) {
          if (key === 'insightName') {
            continue;
          }
          if (favoriteInsight[key] !== this.insightObject[key]) {
            return true;
          }
        }
        return false;
      });
      this.user.dashboardOrder = this.user.dashboardOrder.filter((dashboardObject) => dashboardObject.value !== `insight-query-card-${this.insightName}`);
      this.runFunction('editUser', {
        userId: this.user.id,
        updatedUserData: this.user.toJson(),
      });
    },
    async setInsightFromFavorite (favoriteInsight) {
      this.viewMode = favoriteInsight.viewMode;
      await this.$nextTick();
      this.selectedLocationSchoolId = favoriteInsight.selectedLocationSchoolId;
      await this.$nextTick();
      Object.keys(favoriteInsight).forEach((key) => {
        if (key === 'insightName' || key === 'viewMode' || key === 'selectedLocationSchoolId') {
          return;
        }
        this[key] = favoriteInsight[key];
      });
    },
  },
  watch: {
    async selectedObservationCategoryId () {
      if (this.viewMode === 'category' && this.selectedObservationCategoryId) {
        this.insightCount = null;
        this.insightCountError = false
        try {
          const insightCount = await this.getInsightCount(`category--${this.selectedObservationCategoryId}`);
          this.insightCount = insightCount;
        } catch (e) {
          console.error(e)
          this.insightCountError = true
        }
      }
    },
    async selectedObservationItemId () {
      if (this.viewMode === 'item' && this.selectedObservationItemId) {
        this.insightCount = null;
        this.insightCountError = false
        try {
          const insightCount = await this.getInsightCount(`category--${this.selectedObservationCategoryId}--item--${this.selectedObservationItemId}`);
          this.insightCount = insightCount;
        } catch {
          this.insightCountError = true
        }
      }
    },
    async selectedLocation () {
      if (this.viewMode === 'location' && this.selectedLocation && this.schoolLocationsById && Object.keys(this.schoolLocationsById[this.selectedLocationSchoolId].usageMap).includes(this.selectedLocation)) {
        this.insightCount = null;
        this.insightCountError = false
        try {
          const insightCount = await this.getInsightCount(`location--${encodeURIComponent(this.selectedLocation.trim().toLowerCase())}`);
          this.insightCount = insightCount;
        } catch {
          this.insightCountError = true
        }
      }
    },
    selectedLocationSchoolId () {
      this.selectedLocation = null
    },
    async selectedTag () {
      if (this.viewMode === 'tag' && this.selectedTag) {
        this.insightCount = null;
        this.insightCountError = false
        try {
          const insightCount = await this.getInsightCount(`tag--${encodeURIComponent(this.selectedTag.trim().toLowerCase())}`);
          this.insightCount = insightCount;
        } catch {
          this.insightCountError = true
        }
      }
    },
    async selectedUserId () {
      if (this.viewMode === 'user' && this.selectedUserId) {
        this.insightCount = null;
        this.insightCountError = false
        try {
          const insightCount = await this.getInsightCount(`user--${this.selectedUserId}`);
          this.insightCount = insightCount;
        } catch {
          this.insightCountError = true
        }
      }
    },
    async viewMode () {
      [
        'selectedObservationCategoryId',
        'selectedTag',
        'selectedObservationItemId',
        'selectedLocation',
        'selectedLocationSchoolId',
        'selectedUserId',
      ].forEach((key) => {
        if (!(this.initialInsightData && this.initialInsightData[key])) {
          this[key] = null;
        }
      });

      if (this.viewMode === 'all') {
        try {
          const insightCount = await this.getInsightCount('global');
          this.insightCount = insightCount;
        } catch {
          this.insightCountError = true
        }
      }
    },
    initialInsightData: {
      handler () {
        if (this.initialInsightData) {
          Object.keys(this.initialInsightData).forEach((key) => {
            this[key] = this.initialInsightData[key];
          });
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
