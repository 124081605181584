<template>
  <div class="d-flex flex-column flex-grow-1 pa-4">
    <div>
      <v-btn to="/drills" text color="primary" class="mb-2" active-class="active-route">
        <v-icon>mdi-chevron-left</v-icon>
        View all drills
      </v-btn>
    </div>
    <div class="mb-1">
      <div class="text-h5">
        {{ isEditing ? 'Update' : 'Record' }} Drill
      </div>
    </div>
    <v-progress-circular v-if="isLoadingOriginalDrill" indeterminate color="primary"></v-progress-circular>
    <v-form v-else v-model="isDrillFormValid" ref="drillForm">
      <v-card>
        <v-card-title class="flex-grow-1 flex-row d-flex">
          Drill Information
        </v-card-title>
        <v-card-text>
            <school-select
              v-model="drill.schoolId"
              class="mb-1"
              permission="record drills"
              :rules="[rules.required]" />
            <v-select
              v-model="drill.type"
              :items="drillTypes"
              class="mb-1"
              outlined
              dense
              hide-details="auto"
              label="Drill Type"
              :rules="[rules.required]" />
            <v-text-field
              v-if="drill.type === 'other'"
              class="mb-1"
              outlined
              hide-details="auto"
              dense
              v-model="drill.otherType"
              label="Drill Type (Other)"
              :rules="[rules.required, rules.maxLength(40)]" />
            <v-menu
              ref="menu"
              color="primary"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="drill.drillDate"
                  label="Drill Date"
                  class="mb-1"
                  outlined
                  dense
                  :rules="[rules.required]"
                  clearable
                  hide-details="auto"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="drill.drillDate" no-title scrollable color="primary">
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="$refs.menu.save(drill.drillDate)">Close</v-btn>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="formattedDrillTime"
              :rules="[rules.required, rules.validTime]"
              validate-on-blur
              outlined
              maxlength="5"
              dense
              hide-details="auto"
              label="Drill Time (hh:mm)"
              placeholder="04:30" />
            <div class="d-flex">
              <v-radio-group
                v-model="drill.drillTimeOfDay"
                class="mb-1 mt-0"
                dense
                color="primary"
                hide-details="auto"
                row>
                <v-radio
                  label="AM"
                  value="am" />
                <v-radio
                  label="PM"
                  value="pm" />
              </v-radio-group>
            </div>
            <v-radio-group
              v-model="drill.eventType"
              class="mb-1"
              label="Event Type"
              hide-details="auto"
              :rules="[rules.required]">
              <v-radio
                label="Planned drill"
                value="planned" />
              <v-radio
                label="Table-top exercise"
                value="tableTop" />
              <v-radio
                label="Real event"
                value="real" />
              <v-radio
                label="Other"
                value="other" />
            </v-radio-group>
            <v-text-field
              v-if="drill.eventType === 'other'"
              class="mb-1"
              outlined
              hide-details="auto"
              dense
              v-model="drill.otherEventType"
              label="Event Type (Other)"
              :rules="[rules.required, rules.maxLength(40)]" />

            <v-textarea
              v-model="drill.additionalInfo"
              label="Notes/Additional Information"
              class="mb-1 mt-2"
              outlined
              rows="3"
              counter="1000"
              maxlength="1000" />
        </v-card-text>
      </v-card>
      <v-card 
        v-if="drill.drillDate && !isDrillDateInFuture"
        class="mt-2">
        <v-card-title class="flex-grow-1 flex-row d-flex">
          Drill Results (Optional)
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="formattedDrillResponseTime"
            class="mb-1"
            outlined
            hide-details="auto"
            dense
            validate-on-blur
            maxlength="5"
            label="Response time (mm:ss)"
            :rules="[rules.validResponseTime]" />
          <v-text-field
            v-model="drill.numberOfParticipants"
            class="mb-1"
            outlined
            hide-details="auto"
            dense
            label="Number of Participants"
            type="number" />
            <v-textarea
              v-model="drill.resultsAdditionalInfo"
              label="Notes/Additional Information"
              class="mb-1 mt-2"
              outlined
              rows="3"
              counter="1000"
              maxlength="1000" />
        </v-card-text>
      </v-card>
      <div
        v-if="isDrillDateInFuture"
        class="font-italic text-body-2 my-2">
        NOTE: This drill is scheduled for the future. Once the drill is complete, you can fill out the rest of the form. You can still submit the data now for planning purposes.
      </div>
    </v-form>
    <div v-if="!isLoadingOriginalDrill">
      <div v-if="!isEditing" class="d-flex justify-center">
        <v-btn
          @click="submit()"
          class="mt-4"
          color="primary"
          x-large>
          Submit
        </v-btn>
      </div>
      <div v-else>
        <div class="d-flex justify-center">
          <v-btn
            @click="saveChanges()"
            :disabled="isSavingChanges"
            :loading="isSavingChanges"
            class="mt-4"
            color="primary"
            x-large>
            Save Changes
          </v-btn>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            @click="saveChanges()"
            class="my-1"
            active-class="active-route"
            color="grey darken-1"
            :to="`/drills/drill/${drill.id}`"
            text
            large>
            Cancel
          </v-btn>
        </div>
        <div class="d-flex justify-center">
          <v-btn
            @click="isDeletingDialogVisible = true"
            class="mt-2"
            color="error"
            text
            x-large>
            Permanently Delete Drill
          </v-btn>
        </div>
      </div>
    </div>
    <v-dialog v-model="isDeletingDialogVisible" persistent max-width="528px">
      <v-card>
        <v-card-title>
          Are you sure?
        </v-card-title>
        <v-card-subtitle>
          Are you sure you want to delete this drill? This cannot be undone.
        </v-card-subtitle>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isSubmittingDeletion"
              @click="isDeletingDialogVisible = false"
              color="grey darken-1"
              text
              >No, do not delete
              </v-btn>
              <v-btn
              @click="deleteDrill()"
              :disabled="isSubmittingDeletion"
              :loading="isSubmittingDeletion"
              color="primary"
              >Yes, delete
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import Drill from '../../models/Drill';
import CheckPermissionMixin from '../../mixins/CheckPermissionMixin';
import SchoolSelect from '../../components/common/SchoolSelect.vue';

export default {
  mixins: [
    CheckPermissionMixin,
  ],
  components: {
    SchoolSelect,
  },
  canAtAnySchool: 'record drills',
  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      drill: new Drill(),
      isLoadingOriginalDrill: false,
      isDeletingDialogVisible: false,
      isSubmittingDeletion: false,
      rules: {
        required: value => Boolean(value) || 'This field is required.',
        maxLength (length) {
          return value => (value || '').length <= length || `Max ${length} characters.`;
        },
        validTime (value) {
          const isNumeric = /^\d{1,2}:\d{2}$/.test(value);
          const isValidHour = parseInt(value.slice(0, 2), 10) > 0 && parseInt(value.slice(0, 2), 10) <= 12;
          const isValidMinute = parseInt(value.slice(3), 10) >= 0 && parseInt(value.slice(3), 10) <= 59;
          return isNumeric && isValidHour && isValidMinute || 'Please enter a valid time in the format hh:mm.';
        },
        validResponseTime (value) {
          if (!value) {
            return true;
          }
          const isNumeric = /^\d{1,2}:\d{2}$/.test(value);
          const isValidMinute = parseInt(value.slice(0, 2), 10) >= 0 && parseInt(value.slice(0, 2), 10) <= 99;
          const isValidSecond = parseInt(value.slice(3), 10) >= 0 && parseInt(value.slice(3), 10) <= 59;
          return isNumeric && isValidMinute && isValidSecond || 'Please enter a valid response time in the format mm:ss.';
        },
      },
      drillTypes: [
        {
          text: 'Fire Drill',
          value: 'fire',
        },
        {
          text: 'Severe Weather Drill',
          value: 'severeWeather',
        },
        {
          text: 'Intruder/Lockdown Drill',
          value: 'intruder',
        },
        {
          text: 'Other',
          value: 'other',
        },
      ],
      isDrillFormValid: false,
      isReviewDialogVisible: false,
      isSavingChanges: false,
    }
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'user',
    ]),
    isDrillDateInFuture() {
      if (!this.drill.drillDate) {
        return false
      }
      return new Date(this.drill.drillDate) > new Date()
    },
    formattedDrillTime: {
      get() {
        if (!this.drill.drillTime) {
          return '';
        }
        return this.drill.drillTime;
      },
      set(value) {
        // Strip non-numeric characters
        let time = value.replace(/\D/g, '').slice(0, 4);

        if (time.length === 4) {
          const hours = parseInt(time.slice(0, 2), 10);
          const minutes = time.slice(2);

          time = `${String(hours).padStart(2, '0')}:${minutes}`;
        }

        if (time.length === 3) {
          const hours = parseInt(time.slice(0, 2), 10);
          const minutes = time.slice(2);

          time = `${String(hours).padStart(2, '0')}:${minutes}`;
        }

        if (time.length === 2) {
          const hours = parseInt(time)
          time = `${String(hours).padStart(2, '0')}:`
        }

        this.drill.drillTime = time;
      },
    },
    formattedDrillResponseTime: {
      get() {
        if (!this.drill.responseTime) {
          return '';
        }
        return this.drill.responseTime;
      },
      set(value) {
        // Strip non-numeric characters
        let time = value.replace(/\D/g, '').slice(0, 4);

        if (time.length === 4) {
          const minutes = parseInt(time.slice(0, 2), 10);
          const seconds = time.slice(2);

          time = `${String(minutes).padStart(2, '0')}:${seconds}`;
        }

        if (time.length === 3) {
          const minutes = parseInt(time.slice(0, 2), 10);
          const seconds = time.slice(2);

          time = `${String(minutes).padStart(2, '0')}:${seconds}`;
        }

        if (time.length === 2) {
          const minutes = parseInt(time)
          time = `${String(minutes).padStart(2, '0')}:`
        }

        this.drill.responseTime = time;
      },
    },
  },
  methods: {
    ...mapActions('app', [
      'showSuccess',
    ]),
    async submit() {
      this.$refs.drillForm.validate()
      if (!this.isDrillFormValid) {
        return
      }

      this.drill.recordedByUserId = this.user.id
      await this.drill.save()
      this.showSuccess('Drill recorded successfully')
      this.$router.push('/drills/drill/' + this.drill.id)
    },
    async saveChanges() {
      this.$refs.drillForm.validate()
      if (!this.isDrillFormValid) {
        return
      }
      
      if (this.isOffline) {
        this.drill.save()
      } else {
        this.isSavingChanges = true
        await this.drill.save()
        this.isSavingChanges = false
      }
      this.showSuccess('Drill updated successfully')
    },
    async loadDrill () {
      this.isLoadingOriginalDrill = true
      try {
        const originalDrill = await Drill.getById(this.$route.params.drillId)
        this.drill = originalDrill.clone()
      } finally {
        this.isLoadingOriginalDrill = false
      }
    },
    async deleteDrill() {
      this.isSubmittingDeletion = true
      if (this.isOffline) {
        this.drill.delete()
      } else {
        await this.drill.delete()
      }
      this.isSubmittingDeletion = false
      this.showSuccess('Drill deleted successfully')
      this.$router.replace('/drills')
    },
  },
  mounted () {
    if (this.isEditing) {
      this.loadDrill()
    }
  },
};
</script>
<style lang="scss" scoped>
.active-route::before {
  opacity: 0;
}
</style>