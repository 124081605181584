<template>
  <div class="d-flex flex-column flex-grow-1 pa-4">
    <header>
        <h1>Privacy Policy</h1>
    </header>
    <section>
        <p>Protecting your private information is our priority. This Statement of Privacy applies to any subdomain of schooldogsolutions.com, and SchoolDog Solutions, Inc., and governs data collection and usage. For the purposes of this Privacy Policy, unless otherwise noted, all references to SchoolDog Solutions, Inc. include all subdomains of schooldogsolutions.com and SchoolDog. By using any subdomain of schooldogsolutions.com, you consent to the data practices described in this statement.</p>
    </section>
    <section>
        <h2>Collection of your Personal Information</h2>
        <p>In order to better provide you with products and services offered, SchoolDog may collect personally identifiable information, such as your:</p>
        <ul>
            <li>First and Last Name</li>
            <li>Work E-mail Address</li>
            <li>Phone Number</li>
            <li>Employer</li>
            <li>Job Title and Location</li>
        </ul>
        <p>Please keep in mind that if you directly disclose personally identifiable information or personally sensitive data through SchoolDog's website, this information may be collected and used by others who have access to the website’s tools and services.</p>
        <p>We do not collect any personal information about you unless you voluntarily provide it to us. However, you may be required to provide certain personal information to us when you elect to use certain products or services. These may include: (a) registering for an account;  (b) sending us an email message; (c) creating a secret password; To wit, we will use your information for, but not limited to, helping you submit reports and data using SchoolDog to the account owner and communicating with you in relation to services and/or products you have requested from us. We also may gather additional personal or non-personal information in the future.</p>

    </section>
    <section>
        <h2>Use of your Personal Information</h2>
        <p>SchoolDog collects and uses your personal information to operate and deliver the services you have requested only within the confines of services agreed to between SchoolDog Solutions, Inc. and the participating school district or institution.</p>
        <p>SchoolDog may also use your personally identifiable information to inform you of other products or services available from SchoolDog and its affiliates.</p>
        <p>SchoolDog may disclose your personal information, without notice, if required to do so by law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law or comply with legal process served on SchoolDog or the site; (b) protect and defend the rights or property of SchoolDog; and/or (c) act under exigent circumstances to protect the personal safety of users of SchoolDog, or the public.</p>
    </section>
    <section>
        <h2>Sharing Information with Third Parties</h2>
        <p>SchoolDog does not sell, rent or lease its customer lists to third parties.</p>
        <p>SchoolDog may share data with trusted partners to help perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to SchoolDog, and they are required to maintain the confidentiality of your information.</p>
    </section>
    <section>
        <h2>Opting Out of Data Sharing</h2>
        <p>SchoolDog relies on certain vendors (including but not limited to Google Cloud Platform, Firebase, SendGrid, and Sentry) to provide services that are critical to the website's functionality. As these services are integral to the operation of SchoolDog, opting out of data sharing with these essential services is not possible without rendering the website unusable.</p>
        <p>SchoolDog may use other third-party services for non-essential functions. You have the ability to opt out of data sharing with these non-essential services by contacting <a href="mailto:support@schooldogsolutions.com">support@schooldogsolutions.com</a> using subject line "Opt Out of Data Sharing".</p>
    </section>
    <section>
        <h2>Automatically Collected Information</h2>
        <p>Information about your computer hardware and software may be automatically collected by SchoolDog. This information can include: your IP address, browser type, domain names, access times and referring website addresses. This information is used for the operation of the service, to maintain quality of the service, and to provide general statistics regarding use of the SchoolDog website.</p>
    </section>
    <section>
        <h2>Use of Cookies</h2>
        <p>The SchoolDog website may use "cookies" to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.</p>
        <p>One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize SchoolDog pages, or register with SchoolDog site or services, a cookie helps SchoolDog to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same SchoolDog website, the information you previously provided can be retrieved, so you can easily use the SchoolDog features that you customized.</p>
        <p>You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the SchoolDog services or websites you visit.</p>
    </section>
    <section>
        <h2>Links</h2>
        <p>This website contains links to other sites. Please be aware that we are not responsible for the content or privacy practices of such other sites. We encourage our users to be aware when they leave our site and to read the privacy statements of any other site that collects personally identifiable information.</p>
    </section>
    <section>
        <h2>Security of your Personal Information</h2>
        <p>SchoolDog secures your personal information from unauthorized access, use, or disclosure. SchoolDog uses the following methods for this purpose:</p>
        <ul>
            <li>SSL Protocol</li>
            <li>Database encryption through Firebase</li>
        </ul>
        <p>When personal information (such as a credit card number) is transmitted to other websites, it is protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.</p>
        <p>We strive to take appropriate security measures to protect against unauthorized access to or alteration of your personal information. Unfortunately, no data transmission over the Internet or any wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect your personal information, you acknowledge that: (a) there are security and privacy limitations inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any and all information and data exchanged between you and us through this Site cannot be guaranteed.</p>
        <p>SchoolDog uses Firebase, including its database service Firestore, to store and manage data securely. Data is encrypted both in transit and at rest. Firebase services are governed by Google's privacy policies, which can be reviewed at <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.</p>
    </section>
    <section>
        <h2>User Authentication</h2>
        <p>SchoolDog uses Firebase Authentication to manage user logins securely. We offer multiple methods of authentication: Email/Password, Passwordless Email Sign-In, and Google Single Sign-On (SSO).</p>
        <p>When registering with an Email/Password, we enforce creating strong passwords to enhance account security. A strong password typically includes a mix of uppercase and lowercase letters, numbers, and special characters.</p>
        <p>For users who prefer, we offer the option to log in using Google SSO. This method allows users to authenticate using their Google account credentials, leveraging Google’s security features. For more information on how Google handles your data, please review Google's privacy policy at <a href="https://policies.google.com/privacy" target="_blank">https://policies.google.com/privacy</a>.</p>
        <p>Additionally, we provide a Passwordless Email Sign-In option. This method allows users to sign in using a secure link sent to their email, eliminating the need for a password.</p>
        <p>All methods ensure that your login credentials are handled securely, and we take all necessary precautions to protect your personal information. We never store plaintext passwords, and SchoolDog staff cannot access your password.</p>
    </section>
    <section>
        <h2>Multi-Factor Authentication</h2>
        <p>To enhance the security of your account, SchoolDog offers an opt-in Multi-Factor Authentication (MFA) feature using SMS, powered by Firebase Authentication, a Google product. When you enable MFA, you will receive a verification code via SMS to your mobile device, which you will need to enter in addition to your regular login credentials.</p>
        <p>By opting into MFA, you consent to the use of your mobile phone number for sending verification codes via Firebase Authentication. This added security measure helps protect your account from unauthorized access.</p>
    </section>
    <section>
        <h2>Data Retention</h2>
        <p>SchoolDog retains personal data only for as long as necessary to fulfill the purposes for which it was collected, including compliance with legal, regulatory, accounting, or reporting requirements. The retention period for personal data is determined based on the amount, nature, and sensitivity of the data, the potential risk of harm from unauthorized use or disclosure, the purposes for which we process the data, and whether we can achieve those purposes through other means.</p>
    </section>
    <section>
        <h2>Right to Deletion</h2>
        <p>Subject to certain exceptions set out below, on receipt of a verifiable request from the account manager or owner, we will:</p>
        <ul>
            <li>Disable and delete your personal user account in the SchoolDog system, which may include, but is not limited to, your name, job title/role, and work email. A record will remain in our servers that you were a former user of the SchoolDog system so that records you submitted for the account owner’s use will remain functional and so that the origin of the data is identifiable.</li>
            <li>Direct any service providers to delete your personal information from their records, if applicable.</li>
        </ul>
        <p>Please note that we may not be able to comply with requests to delete your personal information if it is necessary to:</p>
        <ul>
            <li>Preserve a complete and accurate record of observations or other voluntary data that you or others previously collected and/or entered into the record through the use of SchoolDog. These records are the sole property of the school district or account owner.</li>
            <li>Complete the transaction for which the personal information was collected, fulfill the terms of a written warranty or product recall conducted in accordance with federal law, provide a good or service requested by you, or reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract between you and us.</li>
            <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity; or prosecute those responsible for that activity.</li>
            <li>Debug to identify and repair errors that impair existing intended functionality.</li>
            <li>Exercise free speech, ensure the right of another consumer to exercise his or her right of free speech, or exercise another right provided for by law.</li>
            <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when our deletion of the information is likely to render impossible or seriously impair the achievement of such research, provided we have obtained your informed consent.</li>
            <li>Enable solely internal uses that are reasonably aligned with your expectations based on your relationship with us.</li>
            <li>Comply with an existing legal obligation; or</li>
            <li>Otherwise use your personal information, internally, in a lawful manner that is compatible with the context in which you provided the information.</li>
        </ul>
    </section>
    <section>
        <h2>Children Under Thirteen</h2>
        <p>SchoolDog does not knowingly collect personally identifiable information from any students or children, including children under the age of thirteen. Children and students, including children under the age of 13, are not permitted by SchoolDog to login to SchoolDog, hold a user account, or access data in the SchoolDog system.</p>
    </section>
    <section>
        <h2>E-mail or Electronic Communications</h2>
        <p>From time to time, SchoolDog may contact you via email, text, or digital notification for the purpose of providing announcements, promotional offers, alerts, confirmations, surveys, and/or other general communication.</p>
        <p>If you would like to stop receiving marketing or promotional communications via email from SchoolDog, you may make a request to opt out of such communications by contacting the account manager designated by the participating school district or institution.</p>
    </section>
    <section>
        <h2>Advertisements</h2>
        <p>We do not display any advertisements on our website. Additionally, we do not share any user information with third parties for advertising purposes. We do not use tracking technologies or web beacons for the purpose of serving advertisements. Any cookies or similar technologies used on our site are strictly for enhancing user experience and ensuring the functionality of our services.</p>
    </section>
    <section>
        <h2>External Data Storage Sites</h2>
        <p>We may store your data on servers provided by third party hosting vendors with whom we have contracted.</p>
    </section>
    <section>
        <h2>Changes to this Statement</h2>
        <p>SchoolDog reserves the right to change this Privacy Policy from time to time. We will notify you about any changes in our usage of third parties or other significant changes in the way we treat personal information by sending a notice to the primary email address specified in your account, by placing a prominent notice on our website, and/or by updating any privacy information. Your continued use of the website and/or Services available after such modifications will constitute your: (a) acknowledgment of the modified Privacy Policy; and (b) agreement to abide and be bound by that Policy.</p>
    </section>
    <footer>
        <h2>Contact Information</h2>
        <p>SchoolDog welcomes your questions or comments regarding this Statement of Privacy. If you believe that SchoolDog has not adhered to this Statement, please contact SchoolDog at:</p>
        <address>
            SchoolDog Solutions, Inc.<br>
            427 Old Mill Drive<br>
            Carrollton, Georgia 30117<br>
            Email: <a href="mailto:support@schooldogsolutions.com">support@schooldogsolutions.com</a>
        </address>
        <br/>
        <p>Effective as of July 15, 2024</p>
    </footer>
  </div>
</template>

<script>
export default {
};
</script>
