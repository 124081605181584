<template>
<div class="d-flex" style="width: 100%; max-width: 100%;">
  <div class="flex-grow-1" style="width: 100%; max-width: 100%;">
    <v-toolbar
      color="#ffffff"
      style="border-bottom: 1px solid #1b7476;"
      width="100%">

      <!-- Toggle button for sort asc/dsc -->
      <v-btn
        @click="filters.sortAsc = !filters.sortAsc"
        class="mr-2"
        dark
        color="grey darken-1"
        text
      >
        Sort By Date
        <v-icon class="ml-1">{{ filters.sortAsc ? 'mdi-sort-descending' : 'mdi-sort-ascending' }}</v-icon>
      </v-btn>

      <div class="flex-grow-1" />

      <!-- Date Range Picker -->
      <v-menu
        v-if="$vuetify.breakpoint.mdAndUp"
        v-model="isShowingDateRangeMenu"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on }">
          <v-text-field
            :value="dateRangeText"
            label="Date Range"
            class="mr-2"
            prepend-inner-icon="mdi-calendar"
            readonly
            hide-details="auto"
            outlined
            dense
            style="width: 240px; max-width: 240px;"
            v-on="on"
            clearable
            @click:clear="filters.dateRange = []"
          ></v-text-field>
        </template>
        <v-date-picker
          range
          v-model="filters.dateRange"
          @input="datesSelected"
        ></v-date-picker>
      </v-menu>


      <!-- Multi-select for Schools -->
      <v-select
        v-if="$vuetify.breakpoint.mdAndUp" 
        v-model="filters.schoolIds"
        hide-details="auto"
        :items="schoolItems"
        :menu-props="{ offsetY: true }"
        outlined
        dense
        label="Schools"
        style="max-width: 256px;"
        multiple>
        <template v-slot:selection="{ item, index }">
          <span v-if="index === 0">
            {{ filters.schoolIds.length > 1 ? `${filters.schoolIds.length} Schools Selected` : item.text }}
          </span>
        </template>
      </v-select>

      <!-- Filter Button with Dropdown Menu -->
      <v-menu :close-on-content-click="false" offset-y max-width="480px" min-width="320px">
        <template v-slot:activator="{ on }">
          <v-btn text v-on="on" color="grey darken-1" class="ml-1">
            Filters
            <v-icon>mdi-filter-variant</v-icon>
            <v-badge v-if="numberOfActiveMenuFilters" color="primary" :content="numberOfActiveMenuFilters" />
          </v-btn>
        </template>
        <v-list class="pb-2">
          <div class="pt-2 px-2 text-h6">
            Filters
          </div>
          <v-list-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-menu
              v-model="isShowingDateRangeMenu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  :value="dateRangeText"
                  label="Date Range"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  hide-details="auto"
                  outlined
                  dense
                  v-on="on"
                  clearable
                  @click:clear="filters.dateRange = []"
                ></v-text-field>
              </template>
              <v-date-picker
                range
                v-model="filters.dateRange"
              ></v-date-picker>
            </v-menu>
          </v-list-item>
          <v-list-item v-if="!$vuetify.breakpoint.mdAndUp">
            <v-select v-model="filters.schoolIds" hide-details="auto" :items="schoolItems" outlined dense label="Schools" multiple :menu-props="{ offsetY: true }">
              <template v-slot:selection="{ item, index }">
                <span v-if="index === 0">
                  {{ filters.schoolIds.length > 1 ? `${filters.schoolIds.length} Schools Selected` : item.text }}
                </span>
              </template>
            </v-select>
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="filters.entryTypes"
              hide-details="auto"
              :items="[
                { text: 'Praises', value: 'praise' },
                { text: 'Concerns', value: 'concern' },
                { text: 'Other', value: 'other' },
              ]"
              :menu-props="{ offsetY: true }"
              outlined
              dense
              label="Entry Type"
              multiple />
          </v-list-item>
          <v-list-item>
            <v-select
              v-model="filters.walkTypes"
              hide-details="auto"
              :items="[
                { text: 'School-Led', value: 'school' },
                { text: 'District-Led', value: 'district' },
                { text: 'Partner-Led', value: 'partner' },
              ]"
              :menu-props="{ offsetY: true }"
              outlined
              dense
              label="Entry Source"
              multiple />
          </v-list-item>
          <v-list-item v-if="canSeeOtherUsers">
            <users-select
              v-model="filters.userIds"
              hide-details="auto"
              outlined
              show-self
              dense
              label="Users"
              multiple />
          </v-list-item>
          <v-list-item>
            <tag-select
              v-model="filters.tag"
              hide-details="auto"
              clearable
              style="width: 100%"
              name="explore-tag"
              prevent-attach
              label="Tag" />
          </v-list-item>
          <v-list-item>
            <observation-location-select
              :disabled="filters.schoolIds.length !== 1"
              :label="filters.schoolIds.length === 1 ? 'Location' : 'Location (Select single school)'"
              v-model="filters.location"
              prevent-attach
              :school-id="filters.schoolIds.length === 1 ? filters.schoolIds[0] : null"
              name="explore-location"
              style="width: 100%"
              clearable
            ></observation-location-select>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-toolbar>

    <div class="d-flex flex-column flex-grow-1 px-4 pb-4 pt-2">
      <div class="text-h5">
        Entries
      </div>
      <div class="text-subtitle-1 mb-1 font-weight-light" v-if="observationsCount">
        You can access {{ observationsCount }} entries matching the selected filters.
      </div>
      <div v-if="!isLoading && observationsCount && user.can('download data insights reports')">
        <v-btn v-if="observationsCount" @click="generateCsvForWalk()" color="primary" text class="mb-1">
          <v-icon>mdi-file-excel-box</v-icon><span style="padding: 12px">Download CSV</span>
        </v-btn>
        <v-btn v-if="observationsCount" @click="genereatePdfForWalk()" color="primary" text class="mb-1" :disabled="observationsCount !== null && observationsCount > 250">
          <v-icon>mdi-file-pdf-box</v-icon><span style="padding: 12px">Download PDF</span>
        </v-btn>
        <div v-if="observationsCount !== null && observationsCount > 250" class="text-body-1 font-italic mb-1 font-weight-light">
          You cannot generate a PDF report for more than 250 entries. Try filtering your data further.
        </div>
        <v-divider class="mb-2"></v-divider>
      </div>
      <div
        v-if="isLoading || (observations && observations.length)"
        class="d-flex flex-wrap">
        <div
          v-for="(observation, index) in observations"
          :key="observation.id"
          class="d-flex flex-column full-height pa-0"
          :class="{
            'pr-1': $vuetify.breakpoint.mdAndUp && index % 2 === 0,
            'pl-1': $vuetify.breakpoint.mdAndUp && index % 2 === 1,
            'col-12': !$vuetify.breakpoint.mdAndUp,
            'col-6': $vuetify.breakpoint.mdAndUp,
          }">
          <entry-card
            @edit="editingObservation = observation; isEditObservationDialogVisible = true"
            @delete="observations.splice(index, 1)"
            :observation="observation"
            show-school
            show-walk
            style="height: 100%"
            class="mb-2" />
        </div>
        <v-progress-circular
          v-if="isLoading"
          color="primary"
          indeterminate
          class="mx-auto mt-4" />
        <div class="d-flex justify-center" style="width: 100%">
          <v-btn
            v-if="hasMore"
            @click="fetchObservations(true)"
            class="mt-2"
            color="primary"
            text
            >
            Load More<v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </div>
      </div>
      <div v-else>
        <div style="max-width: 100%; width: 100%;" class="d-flex flex-column align-center mt-4">
          <div style="width: 500px; max-width: 100%" class="text-subtitle-1 mb-2 text-center">There are no entries that meet your search criteria. Try updating the filters or logging an entry.</div>
          <img style="width: 360px; max-width: 90%" src="@/assets/images/empty_folder.png" />
        </div>
      </div>
    </div>
  </div>

  <log-entry-dialog
    v-if="isEditObservationDialogVisible"
    v-model="isEditObservationDialogVisible"
    @save="saveEditObservationChanges($event)"
    :initial-observation="editingObservation"
    :school-id="editingObservation.schoolId"
    :walk-id="editingObservation.walkId"
    floating
    is-editing />

  <v-dialog v-model="isDownloadedCsvDialogVisible" max-width="528px">
    <v-card>
      <v-card-title>
        Your CSV Is Ready
      </v-card-title>
      <v-card-text>
        <div>
          Your generated CSV should have downloaded automatically. You can also use the link below to download.
        </div>
        <div class="row justify-center pt-4">
          <v-btn text @click="finishCsvDownload()" color="primary">
            <v-icon>mdi-download</v-icon><span style="padding: 12px">Download Generated CSV</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="isDownloadedCsvDialogVisible = false" color="primary">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isDownloadedPdfDialogVisible" max-width="528px">
    <v-card>
      <v-card-title>
        Your PDF Is Ready
      </v-card-title>
      <v-card-text>
        <div>
          Your generated PDF should have opened automatically. You can also use the link below to download. The link is only valid for 10 minutes after generation.
        </div>
        <div class="row justify-center pt-4">
          <v-btn text :href="reportUrl" target="_blank" color="primary">
            <v-icon>mdi-download</v-icon><span style="padding: 12px">Download Generated PDF</span>
          </v-btn>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="isDownloadedPdfDialogVisible = false" color="primary">
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="isGeneratingReport" persistent max-width="528px">
    <v-card>
      <v-card-title>
        Generating Report...
      </v-card-title>
      <v-card-text>
        <div>
          This might take a few seconds.
        </div>
        <div class="row justify-center pt-4">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

import CheckPermissionMixin from '../../mixins/CheckPermissionMixin';
import UsersSelect from '../../components/common/UsersSelect.vue';
import { collection, getDocs, limit, orderBy, query, startAfter, Timestamp, where } from 'firebase/firestore';
import Observation from '../../models/Observation';
import EntryCard from '../../components/walks/EntryCard.vue';
import TagSelect from '../../components/insights/TagSelect.vue';
import ObservationLocationSelect from '../../components/walks/ObservationLocationSelect.vue';
import LogEntryDialog from '../../components/walks/LogEntryDialog.vue';
import { downloadCsv } from '../../services/DownloadCsvService';

export default {
  name: 'ViewWalksPage',
  mixins: [
    CheckPermissionMixin,
  ],
  canAtAnySchool: 'log entries|manage entries|manage other entries',
  components: {
    UsersSelect,
    EntryCard,
    TagSelect,
    ObservationLocationSelect,
    LogEntryDialog,
  },
  data() {
    return {
      filters: {
        dateRange: [],
        schoolIds: [],
        walkTypes: [],
        userIds: [],
        entryTypes: [],
        location: null,
        tag: null,
        sortAsc: false,
      },
      isShowingDateRangeMenu: false,
      isInitialLoadDone: false,

      isEditObservationDialogVisible: false,
      editingObservation: null,

      observations: [],
      lastVisible: null,
      hasMore: false,
      reportCsvData: null,
      isDownloadedCsvDialogVisible: false,
      isGeneratingReport: false,
      isDownloadedPdfDialogVisible: false,
      reportUrl: null,
      observationsCount: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapState('app', [
      'isOffline',
      'organizationSettings',
      'user',
      'walks',
      'haveWalksLoaded',
      'usersById',
    ]),
    inProgressTableHeaders () {
      return [
        { text: 'School', align: 'left', value: 'schoolName' },
        { text: 'Time Started', align: 'left', value: 'timeCreated' },
        { text: 'Observations', align: 'left', value: 'observations' },
        { value: 'action' },
      ];
    },
    completedTableHeaders () {
      return [
        { text: 'School', align: 'left', value: 'schoolName' },
        { text: 'Time Started', align: 'left', value: 'timeCreated' },
        { text: 'Observations', align: 'left', value: 'observations' },
        { value: 'action' },
        { value: 'reports' },
      ];
    },
    schoolItems() {
      if (!this.organizationSettings || !this.organizationSettings.schools) {
        return [];
      }
      return this.organizationSettings.schools.map((school) => {
        return {
          text: school.name,
          value: school.id,
        }
      })
        .filter((school) => {
          return !this.restrictedSchoolIds || this.restrictedSchoolIds.includes(school.value)
        })
        .toSorted((a, b) => {
          return a.text.localeCompare(b.text)
        })
    },
    restrictedSchoolIds() {
      const permissions = [
        'log entries',
        'manage entries',
        'view entries',
        'view data insights',
      ]
      const schoolIds = new Set()
      for (const permission of permissions) {
        if (this.$can( permission)) {
          return null;
        }
        if (this.$canAtAnySchool(permission)) {
          schoolIds.add(...this.user.permissions[permission].forSchools)
        }
      }
      return Array.from(schoolIds);
    },
    tableHeaders() {
      return [
        { text: 'Time Started', align: 'left', value: 'timeCreated' },
        { text: 'Status', align: 'left', value: 'status' },
        { text: 'Observations', align: 'left', value: 'observations' },
        { value: 'action' },
      ];
    },
    filterOptions () {
      return []
    },
    dateRangeText() {
      if (this.filters.dateRange.length === 2) {
        // Format the dates as needed
        const startDate = new Date(`${this.filters.dateRange[0]} 00:00:00`).toLocaleDateString();
        const endDate = new Date(`${this.filters.dateRange[1]} 00:00:00`).toLocaleDateString();
        return `${startDate} - ${endDate}`;
      } else {
        return 'All Time';
      }
    },
    numberOfActiveMenuFilters () {
      let sum = 0
      if (this.filters.walkTypes.length) {
        sum += 1
      }
      if (this.filters.entryTypes.length) {
        sum += 1
      }
      if (this.filters.userIds.length && this.canSeeOtherUsers) {
        sum += 1
      }
      if (this.filters.tag) {
        sum += 1
      }
      if (this.filters.location) {
        sum += 1
      }
      if (!this.$vuetify.breakpoint.mdAndUp) {
        if (this.filters.schoolIds.length) {
          sum += 1
        }
        if (this.filters.dateRange.length) {
          sum += 1
        }
      }
      return sum
    },
    reportTitle () {
      return 'Entries Report'
    },
    canSeeOtherUsers () {
      let schoolIds = null;
      if (this.filters.schoolIds.length || this.restrictedSchoolIds) {
        schoolIds = this.filters.schoolIds.length ? this.filters.schoolIds.filter(schoolId => !this.restrictedSchoolIds || this.restrictedSchoolIds.includes(schoolId)) : this.restrictedSchoolIds
      }

      if (!schoolIds) {
        return this.user.can('view entries');
      }

      return schoolIds.every(schoolId => this.$canAtSchool('view entries', schoolId));
    },
  },
  methods: {
    ...mapActions({
      showSuccess: 'app/showSuccess',
      syncWalks: 'app/syncWalks',
    }),
    async generateReport(reportType) {
      this.isGeneratingReport = true;
      this.isCompletedWalkDialogVisible = false;

      try {
        const data = await this.runFunction('generateReport', {
          reportType,
          walkId: this.completedWalkDialogWalk.id,
        });
        window.open(data, '_blank');
      } finally {
        this.isGeneratingReport = false
      }
    },
    getSchoolById (id) {
      if (this.organizationSettings) {
        return this.organizationSettings.schools.find(school => school.id === id) || {}
      }
      return {}
    },
    getFetchDataConditions () {
      const conditions = []
      if (this.filters.schoolIds.length || this.restrictedSchoolIds) {
        conditions.push([
          'schoolId',
          'in',
          this.filters.schoolIds.length ? this.filters.schoolIds.filter(schoolId => !this.restrictedSchoolIds || this.restrictedSchoolIds.includes(schoolId)) : this.restrictedSchoolIds,
        ])
      }
      if (this.filters.dateRange.length === 2) {
        conditions.push([
          'timestamp',
          '>=',
          new Date(`${this.filters.dateRange[0]}T00:00:00`),
        ])
        conditions.push([
          'timestamp',
          '<=',
          new Date(`${this.filters.dateRange[1]}T23:59:59`),
        ])
      }
      if (this.filters.walkTypes.length) {
        conditions.push([
          'walkType',
          'in',
          this.filters.walkTypes,
        ])
      }
      if (this.filters.entryTypes.length) {
        conditions.push([
          'observationType',
          'in',
          this.filters.entryTypes,
        ])
      }
      if (!this.canSeeOtherUsers) {
        conditions.push([
          'reportedByUserId',
          '==',
          this.user.id,
        ])
      } else if (this.filters.userIds.length) {
        conditions.push([
          'reportedByUserId',
          'in',
          this.filters.userIds,
        ])
      }
      if (this.filters.tag) {
        conditions.push([
          'searchableTags',
          'array-contains',
          this.filters.tag.trim().toLowerCase(),
        ])
      }
      if (this.filters.location && this.filters.schoolIds.length === 1) {
        conditions.push([
          'searchableLocation',
          '==',
          this.filters.location.trim().toLowerCase(),
        ])
      }
      return conditions
    },
    async fetchObservations (isNextPage = false) {
      this.fetchObservationCount();
      let q = collection(this.db, 'observations');

      const conditions = this.getFetchDataConditions();
      conditions.forEach(condition => {
        q = query(q, where(...condition));
      });

      if (isNextPage && this.lastVisible) {
        q = query(q, orderBy('timestamp', this.filters.sortAsc ? 'asc' : 'desc'), startAfter(this.lastVisible), limit(32));
      } else {
        q = query(q, orderBy('timestamp', this.filters.sortAsc ? 'asc' : 'desc'), limit(32));
      }

      try {
        this.isLoading = true;
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size < 32) {
          this.hasMore = false;
        } else {
          this.hasMore = true;
        }

        if (!isNextPage) {
          this.observations = [];
        }

        querySnapshot.forEach(doc => {
          const observation = new Observation(doc.id, doc.data());
          if (observation.timestamp instanceof Timestamp) {
            observation.timestamp = observation.timestamp.toDate();
          } else if (observation.timestamp.seconds && observation.timestamp.nanoseconds) {
            observation.timestamp = new Date(observation.timestamp.seconds * 1000 + observation.timestamp.nanoseconds / 1000000);
          }
          this.observations.push(observation);
        });

        this.lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
      } catch (error) {
        console.error('Error fetching observations: ', error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchObservationCount () {
      let q = collection(this.db, 'observations');

      const conditions = this.getFetchDataConditions();
      conditions.forEach(condition => {
        q = query(q, where(...condition));
      });

      try {
        const querySnapshot = await getDocs(q);
        this.observationsCount = querySnapshot.size;
      } catch (error) {
        console.error('Error fetching observations: ', error);
      }
    },
    datesSelected() {
      if (!this.filters.dateRange || this.filters.dateRange.length != 2) {
        return;
      }
      // if both dates are selected, sort them
      const startDate = new Date(this.filters.dateRange[0].replace(/-/g, '/'));
      const endDate = new Date(this.filters.dateRange[1].replace(/-/g, '/'));
      if (startDate > endDate) {
        const newStart = this.filters.dateRange[1];
        this.filters.dateRange[1] = this.filters.dateRange[0];
        this.filters.dateRange[0] = newStart;
      }
    },
    async handleQueryParams() {
      const query = this.$route.query;
      if (query.dateRange) {
        this.filters.dateRange = query.dateRange.split(',');
      }
      if (query.schoolIds) {
        this.filters.schoolIds = query.schoolIds.split(',');
      }
      if (query.walkTypes) {
        this.filters.walkTypes = query.walkTypes.split(',');
      }
      if (query.entryTypes) {
        this.filters.entryTypes = query.entryTypes.split(',');
      }
      if (query.userIds) {
        this.filters.userIds = query.userIds.split(',');
      }
      if (query.awaitingMyAction) {
        this.filters.awaitingMyAction = query.awaitingMyAction === 'true';
      }
      if (query.showArchived) {
        this.filters.showArchived = query.showArchived === 'true';
      }
      if (query.sortAsc === 'true') {
        this.filters.sortAsc = query.sortAsc = true;
      }
    },
    async saveEditObservationChanges (entry) {
      const entryId = entry.id;
      const observation = this.observations.find((entry) => entry.id === entryId);
      Object.assign(observation, entry);
    },
    async generateCsvForWalk() {
      this.isGeneratingReport = true;
      try {
        const data = await this.runFunction('generateCsvFromObservationsQuery', {
          query: this.getFetchDataConditions(),
        })
        this.reportCsvData = data.csv
        this.finishCsvDownload()
        this.isDownloadedCsvDialogVisible = true
      } finally {
        this.isGeneratingReport = false
      }
    },
    async genereatePdfForWalk() {
      this.isGeneratingReport = true;

      try {
        const data = await this.runFunction('generateDataInsightsPdf', {
          query: this.getFetchDataConditions(),
          title: this.reportTitle,
        })
        this.reportUrl = data
        window.open(data, '_blank');
        this.isDownloadedPdfDialogVisible = true
      } finally {
        this.isGeneratingReport = false
      }
    },
    async finishCsvDownload() {
      const date = new Date()
      let title = this.reportTitle;
      if (this.startDate && this.endDate) {
        const startDate = this.$options.filters.formatDate(this.startDate, 'YYYY-MM-DD')
        const endDate = this.$options.filters.formatDate(this.endDate, 'YYYY-MM-DD')
        title = `${this.reportTitle} - ${startDate} to ${endDate}`
      }
      downloadCsv(this.reportCsvData, title)
    },
  },
  async mounted() {
    await this.handleQueryParams();
    await this.fetchObservations();
  },
  watch: {
    filters: {
      handler () {
        if (this.filters.location && this.filters.schoolIds.length !== 1) {
          this.filters.location = null;
          return
        }
        this.$router.replace({
          query: {
            ...this.filters,
            dateRange: this.filters.dateRange.join(','),
            schoolIds: this.filters.schoolIds.join(','),
            walkTypes: this.filters.walkTypes.join(','),
            entryTypes: this.filters.entryTypes.join(','),
            userIds: this.filters.userIds.join(','),
          },
        });
        if (this.filters.dateRange.length !== 1) {
          this.fetchObservations();
        }
      },
      deep: true,
    },
    haveWalksLoaded: {
      handler () {
        if (this.haveWalksLoaded && !this.isInitialLoadDone) {
          this.isInitialLoadDone = true
        }
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-select__selections {
  height: 40px;
  overflow: ell;
}
</style>
